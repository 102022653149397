import React, { useState, useCallback, useEffect, useMemo } from "react";

import SBConversation from '@sendbird/uikit-react/Channel';
import SBChannelList from '@sendbird/uikit-react/ChannelList';
import SBChannelSettings from '@sendbird/uikit-react/ChannelSettings'
import withSendbird from '@sendbird/uikit-react/withSendbird';
import ChannelHeader from './customChannelHeader';
import ChannelList from "@sendbird/uikit-react/ChannelList";
import Message from '@sendbird/uikit-react/Channel/components/Message';
import OpenChannelMessage from '@sendbird/uikit-react/OpenChannel/components/OpenChannelMessage';
import i18next from 'i18next';
import {
    GroupChannelModule,
    GroupChannelFilter,
    GroupChannelListOrder,
    MessageFilter,
    MessageCollectionInitPolicy
} from '@sendbird/chat/groupChannel';
import OpenChannel from '@sendbird/uikit-react/OpenChannel';
import { makeStyles, withStyles } from "@material-ui/styles";
import { Switch } from "antd"

import {
    Avatar,
    Badge,
    Button,
    Card,
    CardHeader,
    Fade,
    Paper,
    Popper,
    Typography,
    Grid,
    IconButton as MIconButton
} from "@material-ui/core";


import CustomizedChannelPreviewItem from './customChannelPreviewItem'
import "./color.css"
import './sendbird.less'
const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: "rgba(25, 118, 210, 0.2)"
    }
}));

function CustomizedApp(props) {

    const classes = useStyles();

    // default props
    const { selected_channel_url, userInfo } = props;
    // console.log("auto_select",auto_select)
    const {
        stores: { sdkStore, userStore },
        config: {
            isOnline,
            userId,
            appId,
            accessToken,
            theme,
            userListQuery,
            logger,
            pubSub
        }, notification, setNotification
    } = props;
    const logDefaultProps = useCallback(() => {
        console.log(
            "SDK store list log",
            sdkStore.initialized,
            sdkStore.sdk,
            sdkStore.loading,
            sdkStore.error
        );
        console.log(
            "User store list log",
            userStore.initialized,
            userStore.user,
            userStore.loading
        );
        console.log(
            "Config list log",
            isOnline,
            userId,
            appId,
            accessToken,
            theme,
            userListQuery,
            logger,
            pubSub
        );
    }, [
        sdkStore.initialized,
        sdkStore.sdk,
        sdkStore.loading,
        sdkStore.error,
        userStore.initialized,
        userStore.user,
        userStore.loading,
        isOnline,
        userId,
        appId,
        accessToken,
        theme,
        userListQuery,
        logger,
        pubSub
    ]);
    logDefaultProps();

    // useState
    const [showSettings, setShowSettings] = useState(false);
    // const [] = useState(Notification.permission == "granted")
    const [currentChannelUrl, setCurrentChannelUrl] = useState("");

    useEffect(() => {
        // Do whatever you want after first render
        // Put your code here
        if (selected_channel_url) {
            setCurrentChannelUrl(selected_channel_url)
        }

    }, [])

    function onMobileChat(type) {

        document.getElementsByClassName("sendbird-app__conversation-wrap")[0].style.display = "inherit";
        if (type == "private") {
            document.getElementsByClassName("sendbird-conversation")[0].style.display = "inherit";
            document.getElementsByClassName("sendbird-openchannel-conversation")[0].style.display = "none";
        }
        if (type == "public") {
            document.getElementsByClassName("sendbird-conversation")[0].style.display = "none";
            document.getElementsByClassName("sendbird-openchannel-conversation")[0].style.display = "inline";
        }
        if (window.innerWidth < 576) {
            document.getElementsByClassName("sendbird-app__channellist-wrap")[0].style.display = "none";
        }
    };

    function triggerChat() {
        if (userInfo.role != "Organizer") {
            document.getElementsByClassName("sendbird-openchannel-footer")[0].innerHTML = ""
        }
    }

    function triggerPublic() {
        triggerChat()
        setCurrentChannelUrl("")
        document.getElementsByClassName("sendbird-openchannel-conversation")[0].style.display = "inherit";
        document.getElementsByClassName("sendbird-conversation")[0].style.display = "none";
        onMobileChat("public")


    };

    const onBack = () => {
        console.log("back")
        setCurrentChannelUrl("")
        document.getElementsByClassName("sendbird-app__conversation-wrap")[0].style.display = "none";
        document.getElementsByClassName("sendbird-app__channellist-wrap")[0].style.display = "inline";
    }
    const onNotification = (checked) => { checked ? Notification.requestPermission().then((data) => setNotification(data == 'granted')) : setNotification(false) }

    return (

        <div className="customized-app">
            <div className="sendbird-app__wrap">

                <div className="sendbird-app__channellist-wrap">
                    <div style={{ padding: "5px" }}><Switch checked={notification} onChange={onNotification} /> Desktop Notification</div>
                    <div style={{ padding: "5px" }}>{i18next.t('translations.publicChat', { defaultValue: "Public Chat" })}</div>
                    <div className="customized-channelpreview-item">
                        <Card square onClick={triggerPublic} id="card-public-chat">
                            <CardHeader
                                avatar={<Avatar src="https://hsu-metaverse.chefdigital.io/favicon.png" />}
                                title="IVE Announcement"
                            // title={i18next.t('translations.hsuPublicChat', { defaultValue: "HSU Announcement" })}
                            />
                        </Card>
                    </div>
                    <div style={{ padding: "5px" }} > {i18next.t('translations.privateChat', { defaultValue: "Private Chats" })}</div>
                    <ChannelList
                        queries={{
                            channelListQuery: {
                                includeEmpty: true,
                            }
                        }}
                        onChannelSelect={(channel) => {
                            if (channel && channel.url) {
                                setCurrentChannelUrl(channel.url);
                                triggerChat()
                                onMobileChat("private")
                            }
                        }}
                        disableUserProfile={true}
                        disableAutoSelect={true}
                        renderChannelPreview={
                            ({ channel }) => (
                                <CustomizedChannelPreviewItem
                                    userId={userId}
                                    channel={channel}
                                    currentChannelUrl={currentChannelUrl}
                                />
                            )

                        }


                    />

                </div>

                <div className="sendbird-app__conversation-wrap">

                    <OpenChannel channelUrl={"public"} />
                    <SBConversation
                        channelUrl={currentChannelUrl}
                        renderChannelHeader={() => <ChannelHeader onBack={onBack} />}
                        onChatHeaderActionClick={() => {
                            setShowSettings(false);
                        }}
                    />
                </div>
            </div>
            {
                showSettings && (
                    <div className="sendbird-app__settingspanel-wrap">
                        <SBChannelSettings
                            channelUrl={currentChannelUrl}
                            onCloseClick={() => {
                                setShowSettings(false);
                            }}
                        />
                    </div>
                )
            }
        </div >
    );
}

export default withSendbird(CustomizedApp);