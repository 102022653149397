import React from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../../network/axios/$axios';

import { Menu, Tag } from 'antd';
import { get } from 'lodash';
import i18next from 'i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// userInfo?.isJubliaUser
function MenuItem(props) {
  const { onClose, navigation, location, language, userInfo, selectedModel } =
    props;
  const threeRoutes = [
    '/Landing3d',
    '/Lobby3d',
    '/ExpoHall3d',
    '/SocialLounge3d'
  ];
  const List = [];
  navigation?.map((item) => {
    //   if (item.title === 'Expo Hall') {
    //       List.push(item);
    //       List.push({
    //           title: "Survey",
    //           href: "https://forms.gle/YX6P8gSFdMm6jaxy9",
    //           display_title: {
    //               "en-us": "Survey"
    //           }
    //       })
    //   } else {
    List.push(item);
    //   }
  });

  const openJublia = () => {
    axios('getJublia').then((jubliaRes) => {
      console.log('jubliaRes', jubliaRes);
      let jubliaLink = get(jubliaRes, 'basic_details[0].login_url');
      if (jubliaLink) window.open(jubliaLink, '_blank');
    });
  };
  const menuItem = List.map((item, index) => {
    return (
      // Level 1
      <Menu.Item
        key={item.title}
        onClick={onClose}
        className={
          item.href === location.pathname
            ? 'ant-menu-item-selected'
            : 'ant-menu-item-selected-in-active'
        }
      >
        {item.title === 'Survey' ? (
          <a href={item.href} target="blank">
            {item.display_title && item.display_title[language]}
          </a>
        ) : (
          <Link
            to={
              selectedModel === '3d' && threeRoutes.includes(item.href + '3d')
                ? item.href + '3d'
                : item.href
            }
          >
            {item.display_title && item.display_title[language]}
          </Link>
        )}
      </Menu.Item>
    );
  });
  if (userInfo?.isJubliaUser)
    menuItem.splice(
      -1,
      0,
      <Menu.Item key="jublia">
        <a onClick={() => openJublia()}>{i18next.t('translations.jublia')}</a>
      </Menu.Item>
    );

  const urlCode = {
    'en-us': 'en',
    'zh-hans': 'sc',
    'zh-hant': 'tc'
  };
  const hsuUrlCode = {
    'en-us': 'en/index',
    'zh-hans': 'sc/index',
    'zh-hant': 'tc/index'
  };
  const lists = [
    {
      key: 'https://hsu.cyberport.hk/en/founderstage?m=0',
      href: `https://hsu.cyberport.hk/${urlCode[language]}/founderstage?m=0`,
      title: i18next.t('translations.cfs')
    },
    {
      key: 'https://hsu.cyberport.hk/en/acknowledgement',
      href: `https://hsu.cyberport.hk/${urlCode[language]}/acknowledgement?m=0`,
      title: i18next.t('translations.acknowledgement')
    },
    {
      key: 'https://hsu.cyberport.hk/',
      href: `https://hsu.cyberport.hk/${hsuUrlCode[language]}?m=0`,
      title: i18next.t('translations.hsu')
    },
    {
      key: 'https://hsu.cyberport.hk/en/smartliving',
      href: `https://hsu.cyberport.hk/${urlCode[language]}/smartliving?m=0`,
      title: i18next.t('translations.cslvd')
    }
  ];
  const moreItem = () =>
    lists.map((item) => {
      return (
        <Menu.Item onClick={onClose} key={item.key}>
          <a
            onClick={() => {
              props.UPDATE_SELF_IFRAME_ITEM({
                linkType: 'iframe',
                href: item.href
              });
              props.history.push(`/embeddedPage`);
            }}
          >
            {item.title}
          </a>
        </Menu.Item>
      );
    });
  const display_title = {
    'en-us': 'More',
    'zh-hans': '更多',
    'zh-hant': '更多'
  };

  return (
    <Menu mode="inline" theme="light" className="hp-bg-black-20 hp-bg-dark-90">
      {menuItem}
      {/*<Menu.SubMenu title={display_title[language]}>{moreItem()}</Menu.SubMenu>*/}
    </Menu>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
  selectedModel: state.profile.selectedModel
});
const mapDispatchToProps = (dispatch) => ({
  UPDATE_SELF_IFRAME_ITEM: (item) => {
    dispatch({
      type: 'UPDATE_SELF_IFRAME_ITEM',
      data: item
    });
  }
});
const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(MenuItem);
