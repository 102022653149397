import React, { Component } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import axios from '../network/axios/$axios';
import arrowLeft from '../Image/product/arrow-left.png';
import arrowRight from '../Image/product/arrow-right.png'
import { Button } from 'antd';

class Avatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAvatar: false,
      avatars: [],
      avatarIndex: 0,
      userSavedAvatar: false,
      originalPath: false
    };
    this.handleMessage = this.handleMessage.bind(this)

  }
  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage)
    delete this.handleMessage
    delete this.container
  }

  componentDidMount() {
    const { userInfo } = this.props;
    window.addEventListener("message", this.handleMessage)
    window.testMessage = (msg) => this.handleMessage(msg)

  }

  handleMessage(event) {
    try {
      let data = JSON.parse(event.data)
      const { userInfo } = this.props;
      switch (data.type) {
        case 'NoUserModel':
          this.props.history.replace("/Avatar")
          break;
        case 'SceneLoaded':
          axios("avatarDesign").then(res => {
            axios('threeD', { params: { filter: { resourceId: userInfo?._id } } }).then(res2 => {
              console.log("avatar", res.data[0]?.design)
              const avatars = res.data[0].design;
              const selectedAvatar = res2.data[0]?.settings?.uid || avatars[0].uid;
              this.setState({
                avatars,
                selectedAvatar,
                avatarIndex: avatars.findIndex(em => em.uid == selectedAvatar),
                userSavedAvatar: res2.data[0]?.settings?.uid,
                originalPath: res2.data[0]?.settings?.uid,
                originId: res2.data[0]?._id
              })
              this.container.contentWindow.postMessage(JSON.stringify({
                type: "AvatarData",
                data: avatars.map(em => ({
                  ...em, visible: em.uid == selectedAvatar
                }))
              }), { targetOrigin: "*" })

            })
              .catch((error) => {
                console.log(error);
              });

          })
            .catch((error) => {
              console.log(error);
            });


      }
    }
    catch (e) {
      console.log(e)
      console.log(event.data)
    }
  }
  changeAvatar = (increment) => {
    let { avatarIndex, avatars } = this.state;
    avatarIndex = (avatarIndex + increment) % avatars.length;
    if (avatarIndex == -1) avatarIndex += avatars.length

    this.container.contentWindow.postMessage(JSON.stringify({
      type: "ChangeAvatar",
      data: avatars[avatarIndex]
    }), { targetOrigin: "*" })
    this.setState({
      avatarIndex
    })
  }
  saveAvatar = () => {
    const { userInfo } = this.props;
    const { avatarIndex, avatars, originId } = this.state
    if (originId) {
      axios('updateThreeD', {
        path: originId,
        data: {
          resourceId: userInfo._id,
          link: avatars[avatarIndex].src,
          settings: avatars[avatarIndex]
        }
      }).then(() => {
        this.setState({ userSavedAvatar: avatars[avatarIndex].uid })
        console.log("Saved")
      })
    }
    else {
      axios('createThreeD', {
        data: {
          resourceId: userInfo._id,
          link: avatars[avatarIndex].src
        }
      }).then(() => {
        this.setState({ userSavedAvatar: avatars[avatarIndex].uid })
        console.log("Saved")
      })
    }
  }

  render() {
    const { avatarIndex, avatars, userSavedAvatar } = this.state
    console.log('avatar state', this.state)
    const isCurrentAvatar = avatars[avatarIndex]?.uid && avatars[avatarIndex]?.uid == userSavedAvatar

    return (
      <div className="my-avatar">
        <iframe ref={e => {
          this.container = e;
        }} id="frame" className="frame" src="https://hsu-metaverse.chefdigital.io/upload/threeD/avatar.html"></iframe>

        <Button
          onClick={() => this.changeAvatar(-1)}
          className="arrow-left"
          alt=""
        >Prev</Button>
        <Button onClick={this.saveAvatar} disabled={isCurrentAvatar} >{isCurrentAvatar ? "Current" : "Change"}</Button>

        <Button
          onClick={() => this.changeAvatar(1)}
          className="arrow-right"
          alt=""
        > Next </Button>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
});

const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, null)
);

export default enhance(Avatar);
